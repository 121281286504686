.iconButton {
  margin-right: 15px;
  border: 1px #2367e7 solid;
  background-color: #2367e7;
  color: #fff;
  border-radius: 100% !important;
  transition: 0.3s all;
  .icon {
    width: 15px;
    height: 15px;
  }
}

input.form-control:focus {
  border-color: #00dbeb;
}
