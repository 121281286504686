@import "../../assets/styles/colors";

.btn-primary {
  background-color: $primary !important;
  border-color: $primary !important;
}

.btn-primary:hover {
  background-color: hsl(219, 80%, 30%) !important;
  border-color: hsl(219, 80%, 30%) !important;
}

.btn-secondary {
  background-color: $secondary !important;
  border-color: $secondary !important;
}

.btn-secondary:hover {
  background-color: lighten($color: $secondary, $amount: 7) !important;
  border-color: lighten($color: $secondary, $amount: 7) !important;
}

.bg-custom-gradient {
  background-image: url("../../assets/login-bg-cropped.jpg");
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
}

.login-container {
  padding: 100px;
  display: flex;
  flex-direction: column;
  align-self: center;
  background-color: #fafafa !important;
}

.img-overlay {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 68, 72, 0) 0%,
    hsl(219, 80%, 10%) 100%
  );
  position: absolute;
  // z-index: -1;
}
