@import "../../assets/styles/colors";

.collapseIcon {
  background: $primary;
  color: white;
  border: 1px solid $primary;
  border-radius: 100%;
  transition: 0.3s all;
}

button.collapseIcon:hover {
  background: $white;
  color: $primary;
  border: 1px solid $primary;
  border-radius: 100%;
  transition: 0.3s all;
}
