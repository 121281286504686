.roleUnselected {
  padding: 3px 20px;
  border: 2px #2367e7 solid;
  border-radius: 20px;
  font-weight: 500;
  margin: 10px 5px;
  transition: 0.3s all;
}

div.roleUnselected:hover {
  background-color: #2367e7;
  color: #fff;
  transition: 0.3s all;
  cursor: pointer;
}

.roleSelected {
  padding: 3px 20px;
  border: 2px #2367e7 solid;
  background-color: #2367e7;
  color: #fff;
  border-radius: 20px;
  font-weight: 500;
  margin: 10px 5px;
  transition: 0.3s all;
}
