@import "../../assets/styles/colors";

.pageHeader {
  width: 100%;
  min-height: 70px;
  margin-bottom: 10px;
  background: $white;
  box-shadow: 0px 6px 18px $boxShadowColor;
  padding: 0 30px;

  .searchBox {
    width: 500px;
    display: flex;
    flex-direction: row;
  }

  .actionsBox {
    .iconButton {
      margin-right: 15px;
      border: 1px #2367e7 solid;
      border-radius: 100% !important;
      background-color: #2367e7;
      color: #fff;
      transition: 0.3s all;

      &:last-child {
        margin-right: 0;
      }

      .icon {
        color: $white;
        width: 15px;
        height: 15px;
      }
    }
    .iconButton:hover {
      border: 1px #009aa5 solid;
      background-color: #009aa5;
      transition: 0.3s all;
    }
  }
}

@media screen and (max-width: 768px) {
  .pageHeader {
    padding: 0px 10px;
  }
  .pageHeader .actionsBox .iconButton {
    margin-right: 0px;
  }
  .pageHeader .actionsBox .iconButton .icon {
    width: 15px;
    height: 15px;
    display: flex;
    margin: 5px 0px;
  }
}
