@import "../../assets/styles/colors";

.rosterCard {
  background-color: $white;
  height: 150px;
  box-shadow: 0px 2px 2px $boxShadowColor;
  color: $primary;
  cursor: pointer;
  transition: all 0.35s;
  font-family: "Poppins", sans-serif;

  &:hover {
    background: $primary;
    color: $white;
    transform: scale(0.9);
    box-shadow: 3px 3px 3px $darkPrimary;
  }
}
