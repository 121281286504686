@import "../../assets/styles/colors";

.cancelBtn {
  margin: 15px 0px;
  background: transparent;
  border-radius: 15px;
  padding: 10px 25px;
  border: 2px solid $primary;
  color: #454545;
  font-weight: 500;
  font-family: "Poppins";
  transition: 0.3s all;
}

button.flyerIconButton {
  background-color: transparent;
  border: none;
  padding: 0;
}

.imagesContainer {
  white-space: nowrap;
  overflow-y: scroll;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 150px;
  gap: 0.5em;
  padding: 1em;
  margin: 0 -1em;
  scroll-behavior: smooth;

  .imageHolder {
    position: relative;
    display: inline-flex;
    background-color: hsl(0, 0%, 92%);
    border-radius: 10px;
    // clear: both;
    // margin-top: 20px;
    // margin-right: 50px;
    aspect-ratio: 1;
    overflow: hidden;

    .flyerImage,
    .iconImage,
    .flyerUrl {
      width: 100%;
      height: 100%;
    }

    &:focus-within {
      outline: 1px auto;
    }

    .flyerImage,
    .iconImage {
      object-fit: contain;
      object-position: center;
      transition: transform 0.2s ease-in-out;
      transform-origin: center;
      transform: scale(0.9);
    }

    .flyerUrl {
      text-decoration: underline;
      cursor: pointer;

      &:hover,
      &:focus {
        .flyerImage,
        .iconImage {
          transform: scale(1);
        }
      }
    }

    .iconImage {
      object-fit: 50% !important;
      padding: 20%;
      // max-height: 100px;
      // transition: 0.8s all;
    }

    .fileDelete {
      position: absolute;
      z-index: 50;
      aspect-ratio: 1;
      // height: 40px;
      top: 0;
      right: 0;
      display: grid;
      place-items: center;

      padding: 10px;
      // transform: translate(-25%);
      // margin-top: 5px;
      // margin-left: -40px;

      color: rgb(255, 0, 0);
      box-shadow: #424242;
      background-color: #ffffffaf;

      border-radius: 20px;
      border: none;

      transition: 0.3s all;

      &:hover,
      &:focus {
        color: rgb(166, 0, 0);
        background-color: #ffffff;
      }

      &:focus {
        outline: 1px auto;
      }
    }
  }
}

.flyerIcon {
  color: $primary;
  border: 2px solid $primary;
  padding: 7px;
  font-size: 2.5em;
  border-radius: 50%;
  margin: 5px 10px;
  transition: 0.3s all;
}

.flyerIcon:hover {
  color: $white;
  border: 2px solid $primary;
  background-color: $primary;
  padding: 7px;
  font-size: 2.5em;
  border-radius: 50%;
  margin: 5px 10px;
  transition: 0.3s all;
}

.cancelBtn:hover {
  background-color: $primary;
  color: $white;
  transition: 0.3s all;
}

.submitBtn {
  background: $primary;
  border-radius: 15px;
  padding: 10px 25px;
  border: 2px solid $primary;
  color: $white;
  font-weight: 500;
  font-family: "Poppins";
  transition: 0.3s all;
}

.submitBtn:hover:not([disabled]) {
  background-color: transparent;
  border: 2px solid $primary;
  color: #454545;
  transition: 0.3s all;
}

button:disabled,
button[disabled] {
  border: none;
  background-color: #d7d7d7;
  color: #424242;
}

.spinningLoading {
  font-size: 5px;
  color: $white;
}
