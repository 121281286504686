@import "../../assets/styles/colors";

.menu-item a {
  color: #fff;
  text-decoration: none;
}

.menu-item {
  transition: 0.2s ease-in-out;
}

.menu-item:hover {
  box-shadow: 0 0 1px rgb(0 0 0 / 0%);
  background: #3b475d;
  transition: 0.2s ease-in-out;
}

.active {
  transition: 0.2s ease-in-out;
  background-color: $primary !important;
}

.active:hover {
  transition: 0.2s ease-in-out;
  background-color: darken($color: $primary, $amount: 0.5) !important;
}

.bottomLogo {
  width: 100%;
  height: 35%;
}

.sidebar {
  transition: 0.2s ease-in-out;
  height: 100vh;
  width: 70px;
  background: #132034;
  transition: width 0.25s;
  overflow-x: hidden;
  overflow-y: scroll;

  .logo {
    max-width: 150px;
    width: 100%;
    margin: 1em auto;
    padding: 0 0.4em;
  }

  &.full {
    width: 260px;

    .sidebarAction {
      // justify-content: center;
      // margin-left: 0 !important;
      color: $white !important;
    }
  }

  .sidebarAction {
    margin: 0 auto;
    width: 100%;
    padding: 15px;
    justify-content: flex-end;
    color: $white !important;

    .icon {
      width: 32px;
      height: 32px;
      cursor: pointer;
      color: $white !important;
    }
  }

  .laptopBox {
    height: 150px;
    width: 100%;

    .laptopIcon {
      width: 100px;
      height: 70px;
      margin-left: -25px;
    }

    color: $white;
  }

  .linksBox {
    width: 100%;
    min-height: 600px;

    .sideLink {
      transition: 0.2s ease-in-out;
      width: 100%;
      height: 60px;
      padding: 10px 25px;
      color: $white;
      text-decoration: none;
      text-align: center;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      position: relative;
      white-space: nowrap;

      &:hover {
        color: $primary;
        transition: 0.2s ease-in-out;

        .iconHolder {
          height: 20px;
          width: 20px;
          transition: 0.2s ease-in-out;

          .menuSVG {
            path {
              transition: 0.2s ease-in-out;
              fill: $primary !important;
            }
          }
        }

        .icon {
          color: $darkPrimary;
        }
      }

      .icon {
        width: 28px;
        height: 28px;
        color: $white;
      }

      a:hover {
        fill: $primary;
      }

      p {
        width: calc(100% - 40px);
        text-align: left;
        margin-left: 30px;
      }

      &.active {
        background: $primary;
        color: $white;

        &::before {
          position: absolute;
          content: "";
          height: 100%;
          width: 100%;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          border-left: 2px solid $white;
        }

        .icon {
          color: $darkPrimary;
        }

        .iconHolder {
          height: 20px;
          width: 20px;

          .menuSVG {
            path {
              fill: $white !important;
            }
          }
        }
      }
    }
  }

  .bottomBox {
    padding-bottom: 20px;
    min-height: 100px;

    .footNote {
      color: $white;
      text-transform: capitalize;

      b {
        text-transform: uppercase;
        font-weight: 600;
      }
    }
  }
}

.iconHolder {
  height: 20px;
  width: 20px;
  display: flex;
}
